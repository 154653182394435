import { useState, useEffect, useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import React from "react";
//Reused Assets
import { Logo, Menu, Footer } from "./Home";
import { productosSeleccionadosContext } from "./Productos";
//Fonts
import "../src/static/fonts/Exo-Bold.ttf";
import "../src/static/fonts/Exo-Regular.ttf";
import "../src/static/fonts/Exo-Light.ttf";

export default function Cotizacion() {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const seleccionadosString = searchParams.get("seleccionados");
    const isCotizacionPage = true;
    const [isPageLoaded, setIsPageLoaded] = useState(false);
    useEffect(() => {
        setIsPageLoaded(true);
    }, []);

    const seleccionados =seleccionadosString ? JSON.parse(decodeURIComponent(seleccionadosString)) : [];

    return (
        <body>
            {/* Bootstrap CSS*/}
            <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous" />
            {/* Bootstrap JS*/}
            <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js" integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM" crossorigin="anonymous"></script>

            <Logo />
            <Menu isCotizacionPage={isCotizacionPage}/>
            <GrayInnerBackground seleccionados={seleccionados} />
            <Background />
            <Footer />
        </body>
    )
}

const Background = () => {
    return (
    <div style={{
        backgroundColor: "#f6f6f6",
        height: "0vh",
        position: "relative",
    }}>
        
    </div>
)}

const GrayInnerBackground = ({seleccionados}) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
        window.removeEventListener('resize', handleResize);
    };
    }, []);
    const isSmallMobile = windowWidth < 370;
    return (
        <div style={{
            backgroundColor: "#e6e6e6",
            height: isSmallMobile ? "200vh" : "125vh",
            top: "11vh",
            position: "relative",
            overflowX: "hidden",
        }}>
            <BlackDivider />
            <productosSeleccionadosContext.Provider value={seleccionados}>
            <CotizacionForm seleccionados={seleccionados}/>
            </productosSeleccionadosContext.Provider>
        </div>
    )
}

const BlackDivider = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
        window.removeEventListener('resize', handleResize);
    };
    }, []);
    const isMobile = windowWidth < 550 && windowWidth > 400;
    const isSmallMobile = windowWidth < 400;
    const isHalfScreen = windowWidth >= 750 && windowWidth < 960;
    return (
        <div style={{
            backgroundColor: "#000000",
            height: "20vh",
        }}>
            <div style={{
                margin: 0,
                padding: 0,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "flex-end",
                position: "relative",
                top: "8vh",
                left: isMobile ? "0vw" : isSmallMobile ? "4vw" : isHalfScreen ? "15vh" : "25vh",
            }}>
                <h1 style={{
                    fontFamily: "Exo-Bold",
                    color: "white",
                    fontSize: isMobile ? "2em" : isSmallMobile ? "1.5em" : isHalfScreen ? "2em" : "4.5em",
                    letterSpacing: "0.08em",
                    margin: 0,
                    padding: 0
                }}>COTIZA CON NOSOTROS</h1>
            </div>
        </div>
    )
}

const CotizacionForm = ({ seleccionados }) => {
    const location = useLocation();
    const [producto, setProducto] = useState([]);
    const [formMarginTop] = useState("10vh");
    
    //another one
    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);
        const cotizacionParams = urlParams.get("seleccionados");
        console.log(cotizacionParams);
        const productos = cotizacionParams ? JSON.parse(decodeURIComponent(cotizacionParams)) : [];
        setProducto(productos);
        
    }, [location.search]);
    
    const handleProductoSeleccionado = (productoId) => {
        if (producto.includes(productoId)) {
            setProducto(producto.filter((item) => item !== productoId));
        } else {
            const SelectedProducto = seleccionados.find((item) => item.id === productoId);
            if (SelectedProducto) {
                setProducto([...producto, SelectedProducto]);
            }
        }
    };
    console.log(seleccionados); //vacío?
    
    useEffect(() => {
        localStorage.setItem("producto", JSON.stringify(producto));
    }, [producto]);
    console.log(producto); //vacío también
    useLayoutEffect(() => {
        document.querySelector(".cotizacion-form").style.marginTop = formMarginTop;
    })

    const handleFormSubmit = (e) => {
        e.preventDefault();
    };

    //for mobile
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
        window.removeEventListener('resize', handleResize);
    };
    }, []);
    const isMobile = windowWidth < 550 && windowWidth > 370;
    const isSmallMobile = windowWidth < 370;
    const isHalfScreen = windowWidth >= 750 && windowWidth < 960;

    const mensaje = `¡Hola! Estoy interesado en los siguientes productos: ${seleccionados.map((producto) => producto.nombre).join(", ")}. ¿Podrían brindarme más información?`;
    const whatsappUrl = `https://wa.me/51954007107?text=${encodeURIComponent(mensaje)}`;

    const formStyle = {
            maxWidth: '1000px',
            marginTop: formMarginTop,
            margin: isMobile || isSmallMobile ? '10px' : '0 auto',
            padding: '0px',
    };
    const productoContainerStyle = {
        border: "2px solid #f9880d",
        borderRadius: "5px",
        padding: "10px",
        display: isMobile ? "flex" : "",
        flexDirection: isMobile || isSmallMobile ? "column" : "",
        justifyContent: isSmallMobile ? "flex-start" : "",
        alignItems: isMobile || isSmallMobile ? "flex-start" : "",
    };
    return (
        <div className="cotizacion-form" 
        style={
            formStyle
        }>
            <form onSubmit={handleFormSubmit}>

            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
                <label htmlFor="nombreApellido" style={{ fontFamily: 'Exo-Bold', color: "#000000", fontSize: isMobile || isSmallMobile ? "1em" : "1.5em", marginRight: '10px', width: isMobile || isSmallMobile ? "200px" : '300px', letterSpacing: "0.08em", textAlign: "right" }}>NOMBRE Y APELLIDO</label>
                <input type="text" id="nombreApellido" style={{ flex: 1, padding: '10px', border: "1px solid #f9880d", borderRadius: '5px', fontSize: "1em" }} />
            </div>

            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
                <label htmlFor="correo" style={{ fontFamily: 'Exo-Bold', color: "#000000", fontSize: isMobile || isSmallMobile ? "1em" : "1.5em", marginRight: '10px', width: isMobile || isSmallMobile ? "200px" : '300px', textAlign: "right", letterSpacing: "0.08em" }}>CORREO</label>
                <input type="email" id="correo" style={{ flex: 1, padding: '10px', border: "1px solid #f9880d", borderRadius: '5px', fontSize: "1em"  }} />
            </div>

            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
                <label htmlFor="telefono" style={{ fontFamily: 'Exo-Bold', color: "#000000", fontSize: isMobile || isSmallMobile ? "1em" : "1.5em", marginRight: '10px', width: isMobile || isSmallMobile ? "200px" : '300px', textAlign: "right", letterSpacing: "0.08em" }}>NUMERO DE TELEFONO</label>
                <input type="tel" id="telefono" style={{ flex: 1, padding: '10px', border: "1px solid #f9880d", borderRadius: '5px', fontSize: "1em"  }} />
            </div>

            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
                <label htmlFor="empresa" style={{ fontFamily: 'Exo-Bold', color: "#000000", fontSize: isMobile || isSmallMobile ? "1em" : "1.5em", marginRight: '10px', width: isMobile || isSmallMobile ? "200px" : '300px', textAlign: "right", letterSpacing: "0.08em" }}>NOMBRE DE EMPRESA</label>
                <input type="text" id="empresa" style={{ flex: 1, padding: '10px', border: "1px solid #f9880d", borderRadius: '5px', fontSize: "1em" }} />
            </div>

            {isSmallMobile ? (
        <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '15px' }}>
            <label htmlFor="mensaje" style={{ fontFamily: 'Exo-Bold', color: "#000000", fontSize: "1em", marginBottom: '5px', letterSpacing: "0.08em" }}>MENSAJE ADICIONAL</label>
            <textarea id="mensaje" style={{ padding: '10px', minHeight: '100px', border: "1px solid #f9880d", borderRadius: '5px', fontSize: "1em" }} />
        </div>
        ) : (

            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
                <label htmlFor="mensaje" style={{ fontFamily: 'Exo-Bold', color: "#000000", fontSize: isMobile || isSmallMobile ? "1em" : "1.5em", marginRight: '10px', width: isMobile || isSmallMobile ? "200px" : '300px', textAlign: "right", letterSpacing: "0.08em" }}>MENSAJE ADICIONAL</label>
                <textarea id="mensaje" style={{ flex: 1, padding: '10px', minHeight: '100px', border: "1px solid #f9880d", borderRadius: '5px', fontSize: "1em" }} />
            </div>
            )}

            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
                <label htmlFor="producto" style={{ fontFamily: 'Exo-Bold', color: "#000000", fontSize: isMobile || isSmallMobile ? "1em" : "1.5em", marginRight: '10px', width: isMobile || isSmallMobile ? "200px" : '300px', textAlign: "right", letterSpacing: "0.08em" }}>PRODUCTO</label>
                <div style={productoContainerStyle}>
                    {seleccionados.length === 0 ? (
                        <p style={{ opacity: '0.6',}}>Los productos seleccionados aparecerán aquí.</p>
                    ) : 
                    seleccionados && seleccionados.map((producto) => (
                        <div key={producto.id} >
                            <input style={{margin: "0px 8px 10px 0px"}} type="checkbox" id={producto.id} value={producto.id}
                            checked={seleccionados.some((seleccionado) => seleccionado.id === producto.id)}
                            onChange={() => handleProductoSeleccionado(producto.id)}
                            />
                            <label htmlFor={producto.id} style={{ fontFamily: 'Exo-Bold', color: "#000000", fontSize: isMobile || isSmallMobile ? "1em" : "1.3em", letterSpacing: "0.08em" }}>{producto.nombre}</label>
                        </div>
                        ))}
                </div>
            </div>
            </form>
            <Link to={whatsappUrl} target="_blank">
            <button className="btn btn-outline-light" type="submit"
            style={{
                marginLeft: isMobile || isSmallMobile ? '50%' : isHalfScreen ? "70%" : '86%',
                marginTop: '10px',
                backgroundColor: '#f9880d',
                fontFamily: 'Exo-Bold',
                padding: '10px 30px',
                border: 'none',
                borderRadius: '5px',
                fontSize: isMobile || isSmallMobile ? "1em" : '1.3em',
                cursor: 'pointer',
            }}>ENVIAR</button>
            </Link>
            
        </div>
    )
}