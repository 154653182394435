import React from "react";
import { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
//Assets
import "../src/static/css/Home_styles.css";
import logoMain from "../src/static/images/RYASA-logo-main.png";
import logoFooter from "../src/static/images/RYASA-logo-footer.png";
import chemical1 from "../src/static/images/chemical1.jpg";
import chemical2 from "../src/static/images/chemical2.jpg";
import chemical3 from "../src/static/images/chemical3.jpg";
import circulo from "../src/static/images/RYASAArtboard 1.png";
import videoBackground from "../src/static/videos/background.mp4";
//Fonts
import "../src/static/fonts/Exo-Bold.ttf";
import "../src/static/fonts/Exo-Regular.ttf";
import "../src/static/fonts/Exo-Light.ttf";

export default function Home() {
    const [isPageLoaded, setIsPageLoaded] = useState(false);
    useEffect(() => {
        setIsPageLoaded(true);
    }, []);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
        window.removeEventListener('resize', handleResize);
    };
    }, []);
    const isDesktop = windowWidth >= 2000;
    const isLaptop = windowWidth >= 1700 && windowWidth < 2000;
    const isHalfScreen = windowWidth >= 750 && windowWidth < 960;
    const isMobile = windowWidth < 550 && windowWidth > 400;
    const isSmallMobile = windowWidth < 400;
    return (
    <body className={`app ${isPageLoaded ? 'loaded' : ''}`}>
    {/* Bootstrap CSS*/}
    <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous" />
    {/* Bootstrap JS*/}
    <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js" integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM" crossorigin="anonymous"></script>
    
    
    <div style={{ position: "relative", overflow: "hidden"}}>
        <img src={circulo} alt="circulo" 
        style={{ position: "absolute", 
        top: isMobile || isSmallMobile ? "35%" : isDesktop ? "50%" : isLaptop ? "42%" : isHalfScreen ? "50%" : "40%", 
        left: "10%",
        width: "20%",
        opacity: 0.6, 
        zIndex: 2}}/>
        <div style={{ 
            position: "absolute", 
            display: "flex", 
            flexDirection: "column-reverse", 
            alignItems: "flex-end", 
            justifyContent: "flex-start", 
            top: isMobile || isSmallMobile ? "-60%" : "-40%", 
            left: isMobile || isSmallMobile ? "23%" : isDesktop || isLaptop ? "78%" : isHalfScreen ? "55%" : "73%",
            minWidth: "400px",
            height: "100%", 
            zIndex: 999,
            opacity: 0.5,
            }}>
            <p style={{ color: "white", fontFamily: "Exo-Bold", fontSize: isMobile || isSmallMobile ? "5rem" : isDesktop ? "20rem" : isLaptop ? "16rem" : "15rem", transform: "rotate(-90deg)"}}>RYASA</p>
        </div>
    <Menu />
    <Background />
    <OrangeDivider />
    </div>
    <WhiteDivider />
    <BlackDivider />
    <WhiteDivider2 />
    <MapDivider />
    <Footer />
    </body>
    )
}

export const Logo = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const currentPage = window.location.pathname;
    useEffect(() => {
    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
        window.removeEventListener('resize', handleResize);
    };
    }, []);
    const isMobile = windowWidth < 550;
    const isHalfScreen = windowWidth >= 750 && windowWidth < 960;
    return (
        <div style={{ 
            position: "fixed", top: isMobile ? "15px" : "9px", 
            left: "25px", zIndex: 9999, width: isMobile ? "50%" : isHalfScreen ? "20%" : "auto" }}>
            <Link to="/">
            <img src={logoMain} alt="ryasa-logoMain" 
            style={{ 
                width: (isMobile && currentPage === "/") ? "50%" : isMobile ? "50%" : isHalfScreen ? "80%" : "41%", 
                height: "auto" }} />
            </Link>
        </div>
    )
}


const Background = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
        window.removeEventListener('resize', handleResize);
    };
    }, []);
    const isMobile = windowWidth < 550;
    const isHalfScreen = windowWidth >= 750 && windowWidth < 960;
    return (
        <div
            style={{
                backgroundColor: "black",
                minHeight: isMobile ? "70vh" : "100vh",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-between",
                position: "relative",
                minWidth: isMobile ? "auto" : "1000px",
                overflow: isMobile ? "hidden" : "hidden",
            }}
        >
            
            <div style={{ 
                position: "relative", 
                marginTop: "8%", 
                height: isMobile ? "46vh" : "50vh",
                right: isHalfScreen ? "6%" : "0%",
                display: "flex", 
                flexDirection: "column", 
                alignItems: "center", 
                justifyContent: "center",
                zIndex: "99"
                }}>
                    <div style={{
                        position: "relative",
                        // border: "1px solid white",
                        // borderRadius: "4px",
                        padding: "10px",
                    }}>
                        <div style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                fontFamily: 'Exo-Light',
                                lineHeight: 1,
                                color: "white",
                                opacity: 1,
                            }}>
                            <p style={{ 
                                fontSize: isMobile ? '1.7em' : '4em', 
                                marginRight: '100%' }}>CALIDAD</p>
                            <p style={{ 
                                fontSize: isMobile ? '1.7em' : '4em', 
                                marginLeft: '-40%' }}>Y EFICIENCIA</p>
                            <p style={{ 
                                fontSize: isMobile ? '1.7em' : '4em', 
                                marginRight: '20px' }}><span style={{ marginLeft: '20px', fontSize: '0.6em', verticalAlign: 'super' }}>EN </span>PRODUCTOS</p>
                            <p style={{ 
                                fontSize: isMobile ? '1.7em' : '4em',
                                marginLeft: '33%' }}>QUÍMICOS</p>
                        </div>
                        {/* <MainText /> */}
                    </div>
            </div>
            <div style={{ display: "flex", position: "absolute", 
            top: isMobile ? "21%" : "20%", 
            right: isHalfScreen ? "31%" : "12%", 
            flexDirection: "column",
                alignItems: "center", 
                justifyContent: "center"}}>
                <p style={{
                    color: "white",
                    fontFamily: "Exo-Light",
                    fontSize: isMobile ? "0.7rem" : "",
                }}>QUÍMICA <br />INDUSTRIAL</p>
            </div>
            <video
            autoPlay
            loop
            muted
            
                style={{
                    position: "absolute",
                    top: isMobile ? "-60%" : 0,
                    left: isMobile ? "-70%" : 0,
                    width: isMobile ? "200%" : "100%",
                    height: isMobile ? "200%" : "100%",
                    objectFit: isMobile ? "" : "cover",
                    opacity: 0.5,
                }} alt="background"
            >
                <source src={videoBackground} type="video/mp4" />
            </video>

        </div>
    )
}

export const Menu = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
        window.removeEventListener('resize', handleResize);
    };
    }, []);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const currentPage = window.location.pathname;

    const isMobile = windowWidth < 550;
    const isLaptop = windowWidth >= 1500 && windowWidth < 1800;
    const isHalfScreen = windowWidth >= 750 && windowWidth < 960;
    
    return (
        <div className="menu"
            style={{
            position: "fixed",
            top: "0",
            left: "0",
            width: (isMobile && currentPage === "/") ? "100%" : "100%",
            height: (isMobile && currentPage === "/") ? "12%" : "12%",
            backgroundColor: "black",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            padding: "10px",
            zIndex: "9998",
            }}>
            <Logo />
            
            {isMobile && (
        <button
        style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            padding: "5px",
            backgroundColor: "transparent",
            border: "none",
            cursor: "pointer",
        }}
        onClick={toggleMenu}>
            {menuOpen ? (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#f9880d" class="bi bi-x" viewBox="0 0 16 16">
        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
        </svg>
        ) : (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#f9880d"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                >
                <line x1="3" y1="12" x2="21" y2="12" />
                <line x1="3" y1="6" x2="21" y2="6" />
                <line x1="3" y1="18" x2="21" y2="18" />
                </svg>
            )}
        
        </button>
        )}

        {isMobile && menuOpen && (
        <div style={{
            position: "absolute",
            top: "100%",
            left: "30%",
            width: "70%",
            height: "200%",
            backgroundColor: "black",
            display: "flex",
            flexDirection: "column",
            justifyContent: "right",
            alignItems: "center",
            paddingTop: "10px",
            paddingBottom: "10px",
            }}>
        <Link
            to="/nosotros"
            style={{
                fontFamily: "Exo-Bold",
                color: "#f9880d",
                fontWeight: 500,
                fontSize: "1em",
                marginBottom: "10px",
                textDecoration: "none",
            }}>
            NOSOTROS
            </Link>
            <Link
            to="/productos"
            style={{
                fontFamily: "Exo-Bold",
                color: "#f9880d",
                fontWeight: 500,
                fontSize: "1em",
                marginBottom: "10px",
                textDecoration: "none",
            }}>
            PRODUCTOS
            </Link>
            <Link
            to="/cotizacion"
            style={{
                fontFamily: "Exo-Bold",
                color: "#f9880d",
                fontWeight: 500,
                fontSize: "1em",
                textDecoration: "none",
                minWidth: "200px",
            }}>
            COTIZA CON NOSOTROS
            </Link>
            <div style={{
                padding: "10px",
            }}>
            <Link
            to="http://ryasasaccpe.com/consultacpe/index.html"
            target="_blank">
            <button
                className="btn btn-outline-light"
                style={{
                fontFamily: "Exo-Bold",
                backgroundColor: "#fb8006",
                fontSize: "16px",
                padding: "5px 25px",
                borderRadius: "10px",
                cursor: "pointer",
                opacity: 1,
                width: "auto",
                minWidth: "100px",
                }}>
                Factura Electronica
            </button>
            </Link>
            </div>
        </div>
        )} 
        
        {!isMobile && (
        <div style={{
            position: "relative",
            display: "flex",
            top: "10%",
            left: isHalfScreen ? "25%" : "39%",
            alignItems: "center",
            width: "50%",
        }}>
            <Link
            to="/nosotros"
            style={{
                fontFamily: "Exo-Bold",
                color: "#f9880d",
                fontWeight: 500,
                fontSize: isHalfScreen ? "0.8em" : "1em",
                marginRight: isHalfScreen ? "25px" : "50px",
                textDecoration: "none",
            }}>
            NOSOTROS
            </Link>
            <Link
            to="/productos"
            style={{
                fontFamily: "Exo-Bold",
                color: "#f9880d",
                fontWeight: 500,
                fontSize: isHalfScreen ? "0.8em" : "1em",
                marginRight: isHalfScreen ? "25px" : "50px",
                textDecoration: "none",
            }}>
            PRODUCTOS
            </Link>
            <Link
            to="/cotizacion"
            style={{
                fontFamily: "Exo-Bold",
                color: "#f9880d",
                fontWeight: 500,
                fontSize: isHalfScreen ? "0.8em" : "1em",
                textDecoration: "none",
                minWidth: "200px",
            }}>
            COTIZA CON NOSOTROS
            </Link>
            <div style={{
                position: "relative",
                display: "flex", 
                top: isLaptop ? "-13%" :  "0%",
                left: "0%",
                right: "0%",
                paddingLeft: isHalfScreen ? "0px" : "55px",
                width: "100%",
                height: "100%",
                }}>
            <Link to="http://ryasasaccpe.com/consultacpe/index.html" target="_blank" >
            <button class="btn btn-outline-light"
            style={{
                fontFamily: 'Exo-Bold',
                backgroundColor: "#fb8006",
                fontSize: "16px",
                padding: isMobile ? "5px 15px" : isHalfScreen ? "5px 10px" : "8px 30px",
                borderRadius: "10px",
                cursor: "pointer",
                opacity: 1,
                width: "auto",
                minWidth: "100px",
            }}>Factura Electronica</button>
            </Link>    
            </div>
            </div>
        )}
            </div>
    );
};

const MainText = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
        window.removeEventListener('resize', handleResize);
    };
    }, []);

    const isDesktop = windowWidth >= 1900;
    const isLaptop = windowWidth >= 1300 && windowWidth < 1499;
    const isLaptopBigger = windowWidth >= 1500 && windowWidth < 1899;
    const isNotebook = windowWidth >= 600 && windowWidth < 1299;
    const isMobile = windowWidth < 550 && windowWidth >= 400;
    const isSmallMobile = windowWidth < 400;
    return (
        <div
            style={{
                display: "flex",
                top: isDesktop ? "40%" : isMobile ? "0px" : isSmallMobile ? "0px" : isLaptop ? "-70px" : isLaptopBigger ? "-40px" : "17%",
                left: isDesktop ? "30%": isMobile ? "10%" : isSmallMobile ? "10%" : "10%",
                flexDirection: "column",
                alignItems: "center",
                position: "relative",
                zIndex: 10,
                transform: isSmallMobile ? "translate(0%, 60%)": "translate(0%, 20%)",
            }}>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    fontFamily: 'Exo-Light',
                    lineHeight: 1,
                    color: "#FAF9F6",
                }}>
                <p style={{ 
                    fontSize: isDesktop ? '5em' : isMobile ? '2em' : isSmallMobile ? '1.5em' : isLaptop ? "3.5em" : isNotebook ? "2.5em" : '4em', 
                    marginRight: '100%' }}>CALIDAD</p>
                <p style={{ 
                    fontSize: isDesktop ? '5em' : isMobile ? '2em' : isSmallMobile ? '1.5em' : isLaptop ? "3.5em" : isNotebook ? "2.5em" : '4em', 
                    marginLeft: '-40%' }}>Y EFICIENCIA</p>
                <p style={{ 
                    fontSize: isDesktop ? '5em' : isMobile ? '2em' : isSmallMobile ? '1.5em' : isLaptop ? "3.5em" : isNotebook ? "2.5em" : '4em', 
                    marginRight: '20px' }}><span style={{ marginLeft: '20px', fontSize: '0.6em', verticalAlign: 'super' }}>EN </span>PRODUCTOS</p>
                <p style={{ 
                    fontSize: isDesktop ? '5em' :isMobile ? '2em' : isSmallMobile ? '1.5em' : isLaptop ? "3.5em" : isNotebook ? "2.5em" : '4em',
                    marginLeft: '33%' }}>QUÍMICOS</p>
            </div>
        </div>
    )
};

const OrangeDivider = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
        window.removeEventListener('resize', handleResize);
    };
    }, []);
    const isMobile = windowWidth < 550 && windowWidth >= 400;
    const isSmallMobile = windowWidth < 400;
    const isHalfScreen = windowWidth >= 750 && windowWidth < 960;
    const isNotebook = windowWidth >= 600 && windowWidth < 1299;

    return (
        <div style={{
            boxSizing: "border-box",
            width: "100%",
            height: isMobile ? "200px" : isSmallMobile ? "200px" : "200px",
            backgroundColor: "#f9880d",
            opacity: 1,
            bottom: isMobile? "200px" : isSmallMobile ? "200px" : "210px",
            position: "relative",
            overflow: "hidden",
            justifyContent: "space-between",
        }}>
            <span style={{
                color: "white",
                fontSize: "16px",
                fontFamily: 'Exo-Bold',
                letterSpacing: "0.2em",
                top: "50px",
                left: isMobile ? "8%": isSmallMobile ? "4%" : isHalfScreen ? "30%" : "40%",
                position: "relative",
                margin: 0,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                padding: "10px",
            }}>
                <div style={{ fontSize: isMobile ? '1em' : isSmallMobile ? '0.9em' : '1em', marginRight: "11%" }}>ENCUENTRA LOS</div>
                <div style={{ fontSize: isMobile ? '1em' : isSmallMobile ? '0.9em' : '1em', marginLeft: "1%" }}>PRODUCTOS QUÍMICOS</div>
                <div style={{ fontSize: isMobile ? '1em' : isSmallMobile ? '0.9em' : '1em', marginLeft: "11%" }}>QUE NECESITAS</div>
            </span>
            <span style={{
                display: "flex",
                position: "absolute",
                right: isMobile ? "12%": isSmallMobile ? "5%" : isHalfScreen ? "16%" : isNotebook ? "20%" : "27%",
                top: isMobile ? "35%": "37%",
                transform: "translate(16%, 0%)",
                padding: "10px",
            }}><CotizaConNosotrosButton /></span>
        </div>
    )
};

export const CotizaConNosotrosButton = ({ productosSeleccionados }) => {
    const navigate = useNavigate();
    const handleClick = () => {
        if (!productosSeleccionados || productosSeleccionados.length === 0) {
            navigate("/cotizacion");
        } else {
            const queryParams = encodeURIComponent(JSON.stringify(productosSeleccionados));
        navigate(`/cotizacion` + `?seleccionados=${queryParams}`) ;
        console.log(queryParams);
    }};
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
        window.removeEventListener('resize', handleResize);
    };
    }, []);
    const isMobile = windowWidth < 550;
    const isHalfScreen = windowWidth >= 750 && windowWidth < 960;
    return (
        <div style={{
        }}>
            <button class="btn btn-outline-light" 
            style={{
                backgroundColor: "#f1ab5e",
                fontSize: isMobile ? "10px" : "14px",
                padding: isMobile ? "4px 30px" : isHalfScreen ? "5px 30px" : "5px 50px",
                borderRadius: "10px",
                cursor: "pointer",
                opacity: 0.8,
                fontFamily: 'Exo-Bold',
            }}
            onClick={handleClick}>Cotiza Con <br />Nosotros</button>
        </div>
    )
};

const WhiteDivider = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
        window.removeEventListener('resize', handleResize);
    };
    }, []);
    const isMobile = windowWidth < 550 && windowWidth >= 370;
    const isSmallMobile = windowWidth < 370;
    
    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "857px",
            backgroundColor: "#FAF9F6",
            position: "relative",
            opacity: 1,
            zIndex: 10,
            borderTop: "1px solid black",
            bottom: "230px",
            boxSizing: "border-box",
            margin: 0,
            paddingBottom: 0,
            marginBottom: "-230px"
        }}>
        <TreeImages />
        <WhiteDividerTitle />
        <div style={{ 
            position: "relative",
            bottom: isSmallMobile ? "-2%" : isMobile ? "4%" : "20%",
        }}>
            <NuestrosProductosButton />
        </div>
        
        </div>
    )
}

const TreeImages = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
        window.removeEventListener('resize', handleResize);
    };
    }, []);

    const isDesktop = windowWidth >= 1900;
    const isLaptop = windowWidth >= 1170 && windowWidth < 1899;
    const isMobile = windowWidth < 550 && windowWidth >= 370;
    const isSmallMobile = windowWidth < 370;
    const isHalfScreen = windowWidth >= 750 && windowWidth < 960;
    return (
        <div 
        style={{
            display: "flex",
            justifyContent: "center",
            position: "relative",
            height: isMobile ? "650px" : "500px",
            
        }}>
            <img src={chemical1} 
            className={`container ${isDesktop ? 'desktop' : ''} ${isLaptop ? 'laptop' : ''}`} 
            style={{
                width: isDesktop ? "250px" : (isMobile || isSmallMobile ? "150px" : "300px"),
                height: isDesktop ? "340px": (isMobile || isSmallMobile ? "160px" : "200px"),
                objectFit: "cover",
                position: "absolute",
                left: isDesktop ? "70%": isMobile || isSmallMobile ? "47%" : isHalfScreen ? "58%" : "65%",
                zIndex: 1,
                transform: "translate(20%, 0%)",
                top: isMobile || isSmallMobile ? "40%" : "10%",
                borderRadius: "3px",
                objectFit: 'cover',
                clipPath: isDesktop ? 'inset(0px 0px 10px 40px)' : isMobile || isSmallMobile ? 'inset(0px 20px 0px 40px)' : 'inset(0px 40px 0px 70px)',
            }} class="img-fluid" alt="chemical1" />
            <img src={chemical2} 
            className={`container ${isDesktop ? 'desktop' : ''} ${isLaptop ? 'laptop' : ''}`}
            style={{
                width: isDesktop ? "600px" : (isMobile || isSmallMobile ? "250px" : isHalfScreen ? "350px" : "400px"),
                height: isDesktop ? "auto": (isMobile || isSmallMobile ? "300px" : isHalfScreen ? "500px" : "600px"),
                position: "absolute",
                left: isMobile || isSmallMobile ? "40%" : "63.6%",
                transform: "translate(-50%, 0%)",
                top: isMobile || isSmallMobile ? "50%" : "27%",
                borderRadius: "3px",
                objectFit: 'cover',
                clipPath: isDesktop ? 'inset(0px 0px 9.9% 140px)' : 'inset(0px 0px 50px 110px)',
                zIndex: 2,
            }} class="img-fluid" alt="chemical2" />
            <img src={chemical3} 
            className={`container ${isDesktop ? 'desktop' : ''} ${isLaptop ? 'laptop' : ''}`}
            style={{
                width: isDesktop ? "930px": (isMobile || isSmallMobile ? "300px" : "600px"),
                height: "auto",
                position: "absolute",
                left: isDesktop ? "40%" : isMobile ? "5%" : isSmallMobile ? "-1%" : isHalfScreen ? "35%" : "44%",
                top: isDesktop ? "48%": isMobile || isSmallMobile ? "70%" : "80%",
                transform: "translate(-20%, 0%)",
                borderRadius: "3px",
                zIndex: 3,
                objectFit: 'cover',
                clipPath: isDesktop ? 'inset(50px 320px 60px 340px)' : isMobile ? 'inset(30px 105px 30px 110px)' : isSmallMobile ? "inset(10px 100px 20px 110px)" : 'inset(50px 210px 50px 230px)',

            }} class="img-fluid" alt="chemical3" />
        </div>
    )
}

const WhiteDividerTitle = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
        window.removeEventListener('resize', handleResize);
    };
    }, []);

    const isDesktop = windowWidth >= 1900;
    const isLaptop = windowWidth >= 1170 && windowWidth < 1899;
    const isMobile = windowWidth < 550 && windowWidth >= 370;
    const isSmallMobile = windowWidth < 370;
    const isHalfScreen = windowWidth >= 750 && windowWidth < 960;

    return (
        <div style={{
            position: "relative",
        }}>
            <div style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "left",
                position: "relative",
                width: isMobile || isSmallMobile ? "50%" : "30%",
                height: "auto",
                bottom: isDesktop ? "160%" : isMobile ? "350%" : isSmallMobile ? "320%" : isHalfScreen ? "200%" : "150%",
                left: isMobile || isSmallMobile ? "5%" : "10%",
            }}>
            <h1 style={{
                fontFamily: 'Exo-Light',
                color: "#f9880d",
                lineHeight: 1.2,
                fontSize: isDesktop ? "4em": isMobile ? "2em" : isSmallMobile ? "1.8em" : isHalfScreen ? "2.4em" : "3.5em",
                letterSpacing: "0.25em",
                textAlign: "start",
            }}>
                MÁS DE 27 AÑOS DE EXPERIENCIA
            </h1>
            </div>
            <div style={{
                display: "flex",
                flexDirection: "column",
                position: "absolute",
                width: isMobile || isSmallMobile ? "50%" : "20%",
                height: "auto",
                bottom: isMobile ? "-90px" : isSmallMobile ? "-200px" : isHalfScreen ? "-10vh" : "0vh",
                left: isDesktop ? "21%" : isMobile || isSmallMobile ? "5%" : "10%",
                transform: "translateY(-100%)",
            }}>
            <p style={{
                fontFamily: 'Exo-Regular',
                fontSize: isDesktop ? "1.2em": isMobile ? "0.8em" : isSmallMobile ? "0.8em" : "1em",
                textAlign: "start",
                letterSpacing: "0.06em",
            }}>
                
                Tenemos las herramientas y conocimientos suficientes para entender las necesidades del sector industrial y cumplir sus expectativas. 
            </p>
            
            <div className={`container ${isDesktop ? 'desktop' : ''} ${isLaptop ? 'laptop' : ''}`}
            style={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
                justifyContent: isDesktop ? "flex-end" : "flex-end",
                top: isDesktop ? "100px" : isMobile ? "-125px" : isSmallMobile ? "-150px" : isLaptop ? "-130px" : isHalfScreen ? "80px" : "30px",
                left: isDesktop ? "80%": isMobile || isSmallMobile ? "-185%" : isHalfScreen ? "60%" : "50%",
                transform: isDesktop ? "translateX(600%)" : isMobile || isSmallMobile ? "translateX(300%)" : "translateX(800%)",
                margin: 0,
                padding: 0,
                width: isMobile || isSmallMobile ? "100%" : "40%",

            }}>
            {/* <p style={{
                
                fontFamily: 'Exo-Regular',
                fontSize: isDesktop ? "1.2em" : isMobile ? "0.8em" : isSmallMobile ? "0.8em" : "1em",
                textAlign: "start",
                lineHeight: 1.3,
                letterSpacing: "0.06em",
            }}>Transforma tus
                procesos con 
                nuestra amplia 
                gama de 
                productos 
                químicos 
                industriales.
            </p> */}
            </div>
        </div>
        </div>
    )
}

const NuestrosProductosButton = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
        window.removeEventListener('resize', handleResize);
    };
    }, []);

    const isDesktop = windowWidth >= 1170;
    const isLaptop = windowWidth >= 800 && windowWidth < 1170;
    const isMobile = windowWidth < 550 && windowWidth >= 370;
    const isSmallMobile = windowWidth < 370;

    return (
        <div style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "50px",
            position: "absolute",
            left: "37%",
            transform: isDesktop ? "translate(-50%, 0%)": isMobile ? "translate(-25%, -100%)" : isSmallMobile ? "translate(-26%, 100%)" : "translate(-70%, 0%)",
        }}>
            <Link to="/productos" >
            <button class="btn btn-outline-light" 
            style={{
                backgroundColor: "#f46f01",
                fontSize: isMobile || isSmallMobile ? "14px" : "16px",
                padding: isMobile || isSmallMobile ? "10px 25px" : "15px 35px",
                borderRadius: "10px",
                cursor: "pointer",
                opacity: 0.8,
                fontFamily: 'Exo-Bold',
                width: "auto",
                minWidth: "100px",
            }}>Nuestros Productos</button>
            </Link>
    </div>
    )
}

const BlackDivider = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
        window.removeEventListener('resize', handleResize);
    };
    }, []);

    const isMobile = windowWidth < 550;
    const isHalfScreen = windowWidth >= 750 && windowWidth < 960;

    return (
        <div style={{
            width: "100%",
            height: "300px",
            backgroundColor: "black",
            
        }}>
            <div style={{
                fontFamily: "Exo-Bold",
                color: "#f9880d",
                fontSize: isMobile ? "1em" : isHalfScreen ? "1.3em" : "1.5em",
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
                top: isMobile ? "70px" : "120px",
                letterSpacing: "0.1em",
                textAlign: "center",

            }}>
            {isMobile ? (
        <div style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            padding: "0 20px",
            }}>
            <div style={{ margin: "10px 0" }}>
                <p>Productos Industriales</p>
            </div>
            <div style={{ margin: "10px 0" }}>
                <p>Productos De Limpieza</p>
            </div>
        </div>
        ) : (
        <>
        <div style={{ margin: "0 80px" }}>
            <p>Productos Industriales</p>
        </div>
        <div style={{ margin: "0 80px" }}>
            <p>Productos De Limpieza</p>
        </div>
        </>
        )}

        {isMobile ? (
        <div style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "60%",
            height: "auto",
        }}>
            <div style={{ margin: "10px 0" }}>
                <p>Productos De Laboratorio</p>
            </div>
            
        </div>
        ) : (
        <>
            <div style={{ margin: "0 80px" }}>
                <p>Productos De Laboratorio</p>
            </div>
            
        </>
        )}
        </div>
        </div>
    )
}

const WhiteDivider2 = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
        window.removeEventListener('resize', handleResize);
    };
    }, []);

    const isMobile = windowWidth < 550;
    const isLaptop = windowWidth >= 1170 && windowWidth < 1540;
    const isHalfScreen = windowWidth >= 750 && windowWidth < 960;
    return (
        <div style={{
            width: "100%",
            height: isMobile ? "40vh" : "350px",
            backgroundColor: "#e6e6e6",
        }}>
            <div style={{
                display: "flex",
                alignItems: "center",
                justifyContent: isMobile ? "space-between" : "space-between",
                position: "relative",
            }}>
                <div style={{
                    display: "flex",
                    alignItems: "center",
                    position: "relative",
                    top: isMobile ? "35vh" : isLaptop ? "30vh" : "22vh",
                    marginLeft: isMobile ? "20px" : "50px",
                    width: isMobile ? "0%" : "30%",
            }}>
                <WhiteDivider2Title />
            </div>

            <div style={{
                width: isMobile ? "0%" : "35%",
                height: "1px",
                backgroundColor: "#f9880d",
                marginRight: isMobile ? "0px" : isHalfScreen ? "40px" : "30px",
                marginLeft: isMobile ? "0px" : "0px",
                marginTop: isMobile ? "40px" : "190px",
            }}></div>

                {/* BOTON DE FACUTURA ELECTRONICA */}
                <div style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: isMobile ? "space-between" : "flex-start",
                    position: "relative",
                    top: isMobile ? "120px" : "85px",
                    left: isMobile ? "0%" : "-5%",
                    padding: isMobile ? "20px" : "0",
                }}>
                    <FacturaElectronicaButton />
                </div>

            </div>
        </div>
    )
}

const FacturaElectronicaButton = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
        window.removeEventListener('resize', handleResize);
    };
    }, []);

    const isMobile = windowWidth < 550;
    return (
        <div style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            position: "relative",
        }}>
            <Link to="http://ryasasaccpe.com/consultacpe/index.html" target="_blank" >
            <button class="btn btn-outline-light"
            style={{
                fontFamily: 'Exo-Bold',
                backgroundColor: "#f46f01",
                fontSize: isMobile ? "14px" : "16px",
                padding: isMobile ? "10px 25px" : "15px 35px",
                borderRadius: "10px",
                cursor: "pointer",
                opacity: 0.8,
                width: "auto",
                minWidth: "100px",
            }}>Factura Electronica</button>
            </Link>    
        </div>
    )
}

const WhiteDivider2Title = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
        window.removeEventListener('resize', handleResize);
    };
    }, []);

    const isLaptop = windowWidth >= 1170 && windowWidth < 1540;
    const isMobile = windowWidth < 550;
    const isHalfScreen = windowWidth >= 750 && windowWidth < 960;
    return (
        <div style={{
            position: "relative", 
            height: isMobile ? "180px" : "240px",
            width: isMobile ? "100%" : "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "right",
            position: "absolute",
            bottom: isMobile ? "0px" : isHalfScreen ? "-60px" : "-30px",
        }}>
        <h1 style={{
            fontFamily: 'Exo-Regular',
            lineHeight: 1,
            color: "#f9880d",
            right: "20%",
            fontSize: isMobile ? "1.5em" : isLaptop ? "3.7em" : isHalfScreen ? "2.5em" : "4em",
            letterSpacing: "0.05em",
            textAlign: "start",
        }}>
            CONSULTA TU FACTURA AQUÍ
        </h1>
        </div>
    )
}

const MapDivider = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
        window.removeEventListener('resize', handleResize);
    };
    }, []);

    const isMobile = windowWidth < 550;
    const isHalfScreen = windowWidth >= 750 && windowWidth < 960;

    return (
        <div style={{
            width: "100%",
            height: isMobile ? "200vh" : isHalfScreen ? "140vh" : "900px",
            backgroundColor: "black",
        }}>
            <div style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
                top: "5%",
            }}> 
                <p style={{ 
                    color: "#f9880d",
                    fontFamily: 'Exo-Regular',
                    fontSize: isMobile ? "2.3em" : "3em",
                    paddingRight: "30px",
                    }}>
                    UBÍCANOS
                </p>
                {/* Linea */}
                <div style={{
                    width: isMobile ? "30%" : "50%",
                    height: "1px",
                    backgroundColor: "#f9880d",
                }}></div>
            </div>
            {/* Two Maps */}
            <div style={{
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                alignItems: "center",
                justifyContent: "space-between",
                position: "relative",
                top: "10%",
            }}>
                {/* Map one */}
                <div style={{
                    display: "flex",
                    position: "relative",
                    left: isMobile ? "0" : "10%",
                    width: isMobile ? "95%" : "30%",
                    flexDirection: "column",

                }}>
                    {/* Text 1 */}
                    <div style={{
                        display: "flex",
                        justifyContent: "left",
                        flexDirection: "column",
                        padding: "10px",
                    }}>
                        <p style={{
                            fontFamily: 'Exo-Regular',
                            color: "#f9880d",
                            fontSize: isMobile ? "1.4em" : "1.9em",
                        }}>
                            ALMACÉN MARIANO MELGAR
                        </p>
                        {/* Subrayado */}
                        <div style={{
                            width: "100%",
                            height: "1px",
                            backgroundColor: "#f9880d",
                            padding: 0,
                            position: "relative",
                            bottom: "20px",
                        }}></div>
                    </div>
                        <div style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                    }}>
                        <div style={{
                            width: isMobile ? "90%" : "100%",
                        }}>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d478.43296923039844!2d-71.5075565!3d-16.4012571!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x91424bacb0d880b9%3A0x17118ec943323bad!2sRYASA%20S.A.C!5e0!3m2!1sen!2spe!4v1686621985260!5m2!1sen!2spe" 
                    width="100%" height={isMobile ? "350" : "450"} style={{border:0}} 
                    allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                    </div>
                    {/* Direccion */}
                    <div style={{
                        display: "flex",
                        justifyContent: "left",
                        flexDirection: "column",
                        padding: "10px",
                    }}>
                        <p style={{
                            fontFamily: 'Exo-Regular',
                            color: "#f9880d",
                            fontSize: "1em",
                        }}>
                        Calle San Miguel 407-405, Distrito de Mariano Melgar, Provincia y Departamento de Arequipa.
                        </p>
                    </div>
                </div>
                {/* Map two */}
                <div style={{
                    display: "flex",
                    position: "relative",
                    right: isMobile ? "0" : "10%",
                    width: isMobile ? "95%" : "30%",
                    top: isMobile ? "10vh" : "0",
                    flexDirection: "column",
                }}>
                    {/* Text 2 */}
                    <div style={{
                        display: "flex",
                        justifyContent: "left",
                        flexDirection: "column",
                        padding: "10px",
                    }}>
                        <p style={{
                            fontFamily: 'Exo-Regular',
                            color: "#f9880d",
                            fontSize: isMobile ? "1.4em" : "1.9em",
                        }}>
                            ALMACÉN RIO SECO
                        </p>
                        {/* Subrayado */}
                        <div style={{
                            width: "100%",
                            height: "1px",
                            backgroundColor: "#f9880d",
                            padding: 0,
                            position: "relative",
                            bottom: "20px",
                        }}></div>
                    </div>
                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                    }}>
                        <div style={{
                            width: isMobile ? "90%" : "100%",
                        }}>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1914.20602727225!2d-71.5932037!3d-16.3529589!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9142370e6f8e4b57%3A0x2a6eca57eda41e0a!2sRYASA%20S.A.C-%20ALMACEN%202%20RIO%20SECO!5e0!3m2!1sen!2spe!4v1686622886123!5m2!1sen!2spe" 
                    width="100%" height={isMobile ? "350" : "450"} style={{border:0}} 
                    allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                    </div>
                    {/* Direccion */}
                    <div style={{
                        display: "flex",
                        justifyContent: "left",
                        flexDirection: "column",
                        padding: "10px",
                    }}>
                        <p style={{
                            fontFamily: 'Exo-Regular',
                            color: "#f9880d",
                            fontSize: "1em",
                        }}>
                        Parque Industrial Rio Seco Mz E Lote 15, Distrito de Cerro Colorado, Provincia y Departamento de Arequipa.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export const Footer = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
        window.removeEventListener('resize', handleResize);
    };
    }, []);

    const isMobile = windowWidth < 550 && windowWidth > 370;
    const isSmallMobile = windowWidth < 370;
    const isHalfScreen = windowWidth >= 750 && windowWidth < 960;

    return (
        <footer style={{
            width: "100%",
            height: isMobile || isSmallMobile ? "200px" : "350px",
            backgroundColor: "#f9880d",
            opacity: 1,
            position: "relative",
            marginBottom: "-230px",
            overflowX: "hidden",
        }}>
            <div style={{
                position: "relative",
                top: isMobile ? "15%" : isSmallMobile ? "15%" : "40%",
                zIndex: "1",
                display: "flex",
                flexDirection: "column",
                alignItems: isMobile || isSmallMobile ? "center" : "flex-start",
                justifyContent: isMobile || isSmallMobile ? "flex-start" : "flex-end",
            }}>
            <div style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "right",
                fontFamily: 'Exo-Light',
                color: "#FAF9F6",
                fontSize: isMobile || isSmallMobile ? "0.6em" : isHalfScreen ? "0.9em" : "1.1em",
                position: "absolute",
                left: isMobile ? "5%" : isSmallMobile ? "3%" : isHalfScreen ? "5%" : "10%",
                textAlign: isMobile || isSmallMobile ? "center" : "start",
                lineHeight: isMobile || isSmallMobile ? 0 : 1,
                transform: isMobile || isSmallMobile ? "translate(40%, 150%)" : "translate(0%, 95%)",
            }}>
                <p><a href="/" class="link-light" style={{ color: "#FAF9F6", }}>www.ryasa.com.pe</a></p>
                <p>ventas@ryasa.pe</p>
                <div style={{
                    display: "flex",
                    flexDirection: isMobile || isSmallMobile ? "row" : "column",
                    justifyContent: isMobile || isSmallMobile ? "space-between" : "center",
                }}>
                <p>T:(054)-452343&nbsp;</p>
                <p>C:954007107 - 987953167</p>
                </div>
                
            </div>
            </div>
            <div style={{
                position: "relative",
                top: isMobile ? "15%" : isSmallMobile ? "15%" : "40%",
                left: isMobile ? "5%" : isSmallMobile ? "5%" : "-8%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                zIndex: 1,
            }}>
                <h2 style={{
                    display: "flex",
                    flexDirection: "column",
                    fontFamily: 'Exo-Bold',
                    color: "#FAF9F6",
                    fontSize: isMobile || isSmallMobile ? "0.5em" : isHalfScreen ? "0.9em" : "1em",
                    position: "absolute",
                    left: "40%",
                    letterSpacing: "0.2em",
                    textAlign: "start",
                    marginTop: 0,
                    transform: isMobile || isSmallMobile ? "translate(-120%, 0%)" : "initial",
                }}>
                    <Link to="/nosotros" class="link-light" style={{ marginTop: "0px", color: "#FAF9F6", }}>NOSOTROS</Link>
                    <Link to="/productos" class="link-light" style={{ marginTop: "1.4em", color: "#FAF9F6", }}>PRODUCTOS</Link>
                    <Link to="/cotizacion" class="link-light" style={{ marginTop: "1.4em", color: "#FAF9F6", }}>COTIZA CON NOSOTROS</Link>
                </h2>
            </div>
            <div style={{
                position: "relative",
                display: "flex",
                justifyContent: "center",
                margin: 0,
                marginBottom: isMobile || isSmallMobile ? "-400px" : "-430px",
                top: isMobile || isSmallMobile ? "10%" : isHalfScreen ? "30%" : "35%",
                left: isMobile || isSmallMobile ? "2%" : isHalfScreen ? "13%" : "10%",
            }}>
                <img src={logoFooter} style={{
                    top: "30%",
                    left: isMobile || isSmallMobile ? "0%" : "1.5%",
                    height: isMobile || isSmallMobile ? "50px" : isHalfScreen ? "90px" : "118px",
                    width: "auto",
                    position: "relative",
                }} alt="ryasa-logoFooter"></img>
            </div>
            <div style={{
                position: "relative",
                display: "flex",
                justifyContent: isMobile || isSmallMobile ? "center" : "center",
                width: isMobile || isSmallMobile ? "50px" : "150px",
                margin: "0 auto",
                left: isMobile || isSmallMobile ? "42%" : isHalfScreen ? "45%" : "37%",
                top: isMobile || isSmallMobile ? "190%" : "145%",
            }}>
                <h2 style={{
                    display: "flex",
                    flexDirection: "column",
                    fontFamily: 'Exo-Light',
                    color: "#FAF9F6",
                    fontSize: isMobile || isSmallMobile ? "0.75em" : isHalfScreen ? "1.5em" : "1.7em",
                    marginBottom: 0,
                    marginRight: 0,
                    margin: 0,
                    position: "absolute",
                    right: isMobile || isSmallMobile ? "calc(50% + 20px)" : "calc(80% + 1px)",
                    top: "50%",
                    transform: "translateY(-50%)",
                }}>SÍGUENOS</h2>
                <span>
                <a alt="facebook" href="https://www.facebook.com/ryasaperu/" target="_blank">
                <svg xmlns="http://www.w3.org/2000/svg" width={isMobile || isSmallMobile ? "20": "36"} height={isMobile || isSmallMobile ? "30" : "26"} fill="#FAF9F6" class="bi bi-facebook" viewBox="0 0 16 16">
                <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"/>
                </svg>
                </a>
                </span>
            </div>
            <div style={{
                position: "relative",
                display: "flex",
                justifyContent: isMobile || isSmallMobile ? "center" : "center",
                width: isMobile || isSmallMobile ? "50px" : "150px",
                margin: "0 auto",
                left: isMobile || isSmallMobile ? "42%" : isHalfScreen ? "45%" : "37%",
                top: isMobile || isSmallMobile ? "190%" : "149%",
            }}>
                <h2 style={{
                    display: "flex",
                    flexDirection: "column",
                    fontFamily: 'Exo-Light',
                    color: "#FAF9F6",
                    fontSize: isMobile || isSmallMobile ? "0.75em" : isHalfScreen ? "1.5em" : "1.7em",
                    marginBottom: 0,
                    marginRight: 0,
                    margin: 0,
                    position: "absolute",
                    right: isMobile || isSmallMobile ? "calc(50% + 20px)" : "calc(80% + 1px)",
                    top: "50%",
                    transform: "translateY(-50%)",
                }}>CONTÁCTANOS</h2>
                <span>
                    <a alt="whatsapp" href="https://wa.me/51954007107" target="_blank">
                <svg xmlns="http://www.w3.org/2000/svg" width={isMobile || isSmallMobile ? "30" :"36"} height={isMobile || isSmallMobile ? "20" :"26"} fill="#FAF9F6" class="bi bi-whatsapp" viewBox="0 0 16 16">
                <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z"/>
                </svg>
                </a>
                </span>
            </div>
            <div style={{
                position: "relative",
                top: "40%",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                justifyContent: "flex-end",
                right: isMobile || isSmallMobile ? "5%" : isHalfScreen ? "5%" : "10%",
                top: isMobile || isSmallMobile ? "150%" : "100%",
            }}>
                <p style={{
                    fontFamily: 'Exo-Light',
                    color: "#FAF9F6",
                    fontSize: isMobile || isSmallMobile ? "0.5em" : "1.05em",
                    letterSpacing: "0.1em",
                }}>QUÍMICA INDUSTRIAL</p>
            </div>
        </footer>
    )
}